var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "table",
      on: {
        "&scroll": function ($event) {
          return _vm.getScroll($event)
        },
      },
    },
    [
      _c(
        "el-tabs",
        {
          staticClass: "tb-tabs tabs-bg",
          attrs: { type: "card" },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c("el-tab-pane", { attrs: { disabled: "" } }, [
            _c("span", { attrs: { slot: "label" }, slot: "label" }, [
              _c("h3", [_vm._v("题集详情")]),
            ]),
          ]),
          _vm._l(_vm.tabMapOptions, function (item) {
            return _c(
              "el-tab-pane",
              { key: item.key, attrs: { label: item.label, name: item.key } },
              [
                _vm.activeName == item.key && _vm.canShow
                  ? _c("contest-detail", {
                      attrs: {
                        type: item.key,
                        contestInfo: _vm.editData,
                        scrollTopTable: _vm.scrollTopTable,
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          }),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }